.task-item-wrapper {
  display: flex;
  width: 100%;
  margin-top: 5px;
  height: 25px;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 2px 2px lightgrey;
  align-items: center;
}

.task-item-check {
  width:25px;
  margin-top: 5px;
  padding-left: 10px;
}

.task-item-title {
  flex: auto;
}

.task-item-actions {
  padding-right: 10px;
  margin-top: 5px;
}

.task-item-actions:hover i {
  color: green;
}

.task-item-check:hover i {
  color: green;
}

input#editTask {
  width: 80%;
}